import Vue from 'vue'
import Router from 'vue-router'
import { roleAccess } from '@/../../shared/constants/constantsGeneral.json'
import UserService from '../../shared/services/UserService'

/**
 * pages - from now on, lazy load pages only when page is called
 * every page with new design will be created under this new folder 'pages', instead of 'views'
 * path name should be in Dutch, instead of English (except home view which is needed for new identity server setup)
 * use parent & child route relationship for nested paths (see course details in provider ui)
 * */

// Portfolio
const PortfolioDashboard = () => import('./pages/Portfolio/PortfolioDashboard')
const PortfolioOverview = () => import('./pages/Portfolio/PortfolioOverview')

const CourseDetails = () => import('./pages/Portfolio/CourseEdit/CourseDetails')
const CourseCreate = () => import('./pages/Portfolio/CourseCreate')

// Edudex import
const EdudexImportPage = () => import('./pages/Portfolio/EdudexImportPage')
const EdudexImportDetailsPage = () => import('./pages/Portfolio/EdudexImportDetailsPage')

// Order
const OrderDetails = () => import('./pages/Order/OrderDetails')
const ProjectOrderDetails = () => import('./pages/Order/ProjectOrderDetails.vue')
const OrderDashboard = () => import('./pages/Order/OrderDashboard')
const OrderOverview = () => import('./pages/Order/OrderOverview')

// Result
const ResultDashboard = () => import('./pages/Result/ResultDashboard')
const OrderFeesOverview = () => import('./pages/Result/OrderFeesOverview')
const PriceAgreementsOverview = () => import('./pages/Result/PriceAgreementsOverview')
const HistoricalOrdersOverview = () => import('./pages/Result/HistoricalOrdersOverview')

// Provider
const ProviderSettingsPage = () => import('./pages/Provider/ProviderSettingsPage')

// Users list
const UsersOverview = () => import('./pages/Users/UsersOverview.vue')

// Fulfillment
const EducationalFulfillmentOverview = () => import('./pages/Fulfillment/EducationalFulfillmentOverview')

const ErrorPage = () => import('./pages/ErrorPage')
const LegalAgreements = () => import('./pages/LegalAgreements')

// Provider Settings Page
const ProviderSettingsGeneralInfoTab = () => import('./pages/Provider/ProviderSettings/ProviderSettingsGeneralInfoTab')
const ProviderSettingsEmailTab = () => import('./pages/Provider/ProviderSettings/ProviderSettingsEmailTab')
const ProviderSettingsFinancialTab = () => import('./pages/Provider/ProviderSettings/ProviderSettingsFinancialTab')
const ProviderCostTypes = () => import('./pages/Result/ProviderCostTypes')

// Provider Mail settings
const EmailSettings = () => import('./pages/EmailSettings.vue')

// Provider Support center 
const SupportCenter = () => import('../../shared/pages/SupportCenter/SupportCenter.vue')
const SupportCenterContact = () => import('../../shared/pages/SupportCenter/Contacts.vue')
const SupportCenterWhatsNew = () => import('../../shared/pages/SupportCenter/WhatsNew.vue')
const SupportCenterIncompany = () => import('../../shared/pages/SupportCenter/Incompany.vue')
const SupportCenterVideos = () => import('../../shared/pages/SupportCenter/VideoComponents/Videos.vue')
const SupportCenterTermsAndConditions = () => import ('../../shared/pages/SupportCenter/TermsAndConditions.vue')
const SupportCenterUserRoles = () => import ('../../shared/pages/SupportCenter/UserRoles.vue')
const SupportCenterEdudex = () => import ('../../shared/pages/SupportCenter/Edudex.vue')
const SupportCenterPurchaseAgreement = () => import('../../shared/pages/SupportCenter/PurchaseAgreement.vue')

// NotFound
const NotFound = () => import('./pages/NotFound.vue')

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    hash: false,
    linkActiveClass: 'is-active-link', // custom class name replacing VueRouter default class .router-link-active-exact
    routes: [
        {
            path: '/email-settings',
            name: 'mail-settings',
            component: EmailSettings,
            meta: {
                requiresAuth: true,
                roleAccess: [
                    roleAccess.admin,
                    roleAccess.adminReadOnly,
                    roleAccess.providerAdmin,
                    roleAccess.accountManager,
                    roleAccess.backofficeEmployee,
                    roleAccess.portfolioAdministrator
                ]
            }
        },
        {
            path: '/',
            component: PortfolioDashboard,
            redirect: '/portfolio',
            meta: {
                requiresAuth: true,
                roleAccess: [
                    roleAccess.admin,
                    roleAccess.adminReadOnly,
                    roleAccess.providerAdmin,
                    roleAccess.portfolioAdministrator
                ]
            }
        },
        // Portfolio 
        {
            path: '/portfolio',
            name: 'portfolio-dashboard',
            component: PortfolioDashboard,
            meta: {
                requiresAuth: true,
                roleAccess: [
                    roleAccess.admin,
                    roleAccess.adminReadOnly,
                    roleAccess.providerAdmin,
                    roleAccess.portfolioAdministrator
                ]
            }
        },
        {
            path: '/portfolio/overview',
            name: 'portfolio-overview',
            component: PortfolioOverview,
            meta: {
                requiresAuth: true,
                roleAccess: [
                    roleAccess.admin,
                    roleAccess.adminReadOnly,
                    roleAccess.providerAdmin,
                    roleAccess.portfolioAdministrator
                ]
            }
        },
        {
            path: '/portfolio/import',
            name: 'edudex-import',
            component: EdudexImportPage,
            meta: {
                requiresAuth: true,
                roleAccess: [
                    roleAccess.admin,
                    roleAccess.adminReadOnly,
                    roleAccess.providerAdmin,
                    roleAccess.portfolioAdministrator
                ]
            }
        },
        {
            path: '/portfolio/import/:id',
            name: 'edudex-import-details',
            component: EdudexImportDetailsPage,
            meta: {
                requiresAuth: true,
                roleAccess: [
                    roleAccess.admin,
                    roleAccess.adminReadOnly,
                    roleAccess.providerAdmin,
                    roleAccess.portfolioAdministrator
                ]
            }
        },
        {
            path: '/portfolio/create',
            name: 'course-create',
            component: CourseCreate,
            meta: {
                requiresAuth: true,
                roleAccess: [
                    roleAccess.admin,
                    roleAccess.adminReadOnly,
                    roleAccess.providerAdmin,
                    roleAccess.portfolioAdministrator
                ]
            }
        },
        {
            path: '/portfolio/duplicate',
            name: 'course-duplicate',
            component: CourseDetails,
            meta: {
                requiresAuth: true,
                roleAccess: [
                    roleAccess.admin,
                    roleAccess.adminReadOnly,
                    roleAccess.providerAdmin,
                    roleAccess.portfolioAdministrator
                ]
            }
        },
        {
            path: '/portfolio/:id',
            name: 'course-details',
            component: CourseDetails,
            meta: {
                requiresAuth: true,
                roleAccess: [
                    roleAccess.admin,
                    roleAccess.adminReadOnly,
                    roleAccess.providerAdmin,
                    roleAccess.portfolioAdministrator
                ]
            }
        },
        // Order
        {
            path: '/order',
            name: 'order-dashboard',
            component: OrderDashboard,
            meta: {
                requiresAuth: true,
                roleAccess: [
                    roleAccess.admin,
                    roleAccess.adminReadOnly,
                    roleAccess.providerAdmin,
                    roleAccess.backofficeEmployee
                ]
            }
        },
        {
            path: '/order/overview',
            name: 'order-overview',
            component: OrderOverview,
            meta: {
                requiresAuth: true,
                roleAccess: [
                    roleAccess.admin,
                    roleAccess.adminReadOnly,
                    roleAccess.providerAdmin,
                    roleAccess.backofficeEmployee
                ]
            }
        },
        {
            path: '/order/:id',
            name: 'order-details',
            component: OrderDetails,
            meta: {
                requiresAuth: true,
                roleAccess: [
                    roleAccess.admin,
                    roleAccess.adminReadOnly,
                    roleAccess.providerAdmin,
                    roleAccess.backofficeEmployee
                ]
            }
        },
        {
            path: '/order/project/:id',
            name: 'project-order-details',
            component: ProjectOrderDetails,
            meta: {
                requiresAuth: true,
                roleAccess: [
                    roleAccess.admin,
                    roleAccess.adminReadOnly,
                    roleAccess.providerAdmin,
                    roleAccess.backofficeEmployee
                ]
            }
        },
        // Fulfillment
        {
            path: '/leerhistorie',
            name: 'educational-fulfillment-overview',
            component: EducationalFulfillmentOverview,
            meta: {
                requiresAuth: true,
                roleAccess: [
                    roleAccess.admin,
                    roleAccess.adminReadOnly,
                    roleAccess.providerAdmin,
                    roleAccess.backofficeEmployee
                ]
            }
        },
        // Result
        {
            path: '/result',
            name: 'result-dashboard',
            component: ResultDashboard,
            meta: {
                requiresAuth: true,
                roleAccess: [
                    roleAccess.admin,
                    roleAccess.adminReadOnly,
                    roleAccess.providerAdmin,
                    roleAccess.accountManager
                ]
            }
        },
        {
            path: '/result/resultaten',
            name: 'historical-orders-overview',
            component: HistoricalOrdersOverview,
            meta: {
                requiresAuth: true,
                roleAccess: [
                    roleAccess.admin,
                    roleAccess.adminReadOnly,
                    roleAccess.providerAdmin,
                    roleAccess.accountManager
                ]
            }
        },
        {
            path: '/result/vergoedingen',
            name: 'order-fees-overview',
            component: OrderFeesOverview,
            meta: {
                requiresAuth: true,
                roleAccess: [
                    roleAccess.admin,
                    roleAccess.adminReadOnly,
                    roleAccess.providerAdmin,
                    roleAccess.accountManager
                ]
            }
        },
        {
            path: '/result/kortingsafspraken',
            name: 'price-agreements-overview',
            component: PriceAgreementsOverview,
            meta: {
                requiresAuth: true,
                roleAccess: [
                    roleAccess.accountManager
                ]
            }

        },
        {
            path: '/result/korting',
            name: 'provider-cost-types',
            component: ProviderCostTypes,
            meta: {
                requiresAuth: true,
                roleAccess: [
                    roleAccess.accountManager
                ]
            }
        },
        // Provider
        {
            path: '/leveranciersgegevens/',
            name: 'provider-settings',
            component: ProviderSettingsPage,
            children: [{
                path: 'algemeen',
                name: 'provider-settings-general-info-tab',
                component: ProviderSettingsGeneralInfoTab
            },
            {
                path: 'email',
                name: 'provider-settings-email-tab',
                component: ProviderSettingsEmailTab
            },
            {
                path: 'handelsnamen',
                name: 'provider-settings-financieel-tab',
                component: ProviderSettingsFinancialTab
            }
            ],
            meta: {
                requiresAuth: true,
                roleAccess: [
                    roleAccess.adminReadOnly,
                    roleAccess.providerAdmin,
                    roleAccess.accountManager
                ]
            }
        },
        // Users
        {
            path: '/users',
            name: 'users-overview',
            component: UsersOverview
        },

        // Others
        {
            path: '/legal-agreements',
            name: 'legal-agreements',
            component: LegalAgreements,
            meta: {
                requiresAuth: true,
                roleAccess: [
                    roleAccess.admin,
                    roleAccess.adminReadOnly,
                    roleAccess.providerAdmin,
                    roleAccess.accountManager,
                    roleAccess.backofficeEmployee,
                    roleAccess.portfolioAdministrator
                ]
            }
        },
        {
            path: '/onbevoegd',
            name: 'error-page',
            component: ErrorPage,
            meta: {
                requiresAuth: true,
                roleAccess: [
                    roleAccess.admin,
                    roleAccess.adminReadOnly,
                    roleAccess.providerAdmin,
                    roleAccess.accountManager,
                    roleAccess.backofficeEmployee,
                    roleAccess.portfolioAdministrator
                ]
            }
        },
        {
            path: '/support-center/',
            component: SupportCenter,
            children: [
                {
                    path: '',
                    redirect: 'contacts'
                },
               
                {
                    path: 'contacts',
                    name: 'contacts',
                    component: SupportCenterContact
                },
                {
                    path: 'whats-new',
                    name: 'whats-new',
                    component: SupportCenterWhatsNew
                },
                {
                    path: 'incompany',
                    name: 'incompany',
                    component: SupportCenterIncompany
                },
                {
                    path: 'purchase-agreement',
                    name: 'purchase-agreement',
                    component: SupportCenterPurchaseAgreement
                },
                {
                    path: 'support-videos',
                    name: 'support-videos',
                    component: SupportCenterVideos
                },
                {
                    path: 'terms-and-conditions',
                    name: 'terms-and-conditions',
                    component: SupportCenterTermsAndConditions
                },
                {
                    path: 'user-roles',
                    name: 'user-roles',
                    component: SupportCenterUserRoles
                },

                {
                    path: 'edudex',
                    name: 'edudex',
                    component: SupportCenterEdudex
                  },
            ],
            meta: {
                requiresAuth: true,
                roleAccess: [
                    roleAccess.admin,
                    roleAccess.adminReadOnly,
                    roleAccess.providerAdmin,
                    roleAccess.accountManager,
                    roleAccess.backofficeEmployee,
                    roleAccess.portfolioAdministrator
                ]
            }
        },
        {
            path: "/NotFound",
            name: "NotFound",
            component: NotFound,
            props: true
        },
        {
            path: '/:catchAll(.*)',
            component: NotFound,
            beforeEnter: (to, from, next) => {
                next({ path: '/NotFound', query: {} })
            }
        }
    ],
    scrollBehavior(to, from) {
        if (to.path !== from.path) {
            return { x: 0, y: 0 }
        }
    }
})

function getDeepLink() {
    const deepLink = JSON.parse(localStorage.getItem('provider_deepLink'))

    if (deepLink) {
        localStorage.removeItem('provider_deepLink')
        return deepLink
    }

    return null
}

window.popStateDetected = false

window.addEventListener('popstate', () => {
    window.popStateDetected = true
})


router.beforeEach(async (to, from, next) => {
    const userService = new UserService()
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)

    if (requiresAuth) {
        const user = await userService.getUser()

        if (user == null) {
            if (to.path !== '/') {
                if (to.query) {
                    localStorage.setItem('provider_deepLink', JSON.stringify({ path: to.path, query: to.query }))
                } else {
                    localStorage.setItem('provider_deepLink', JSON.stringify({ path: to.path }))
                }
            }
            return
        }

        const userRoles = await userService.getUserRoles()
        const hasAccess = userRoles.some(role => to.matched[0].meta.roleAccess.includes(role))

        if (hasAccess) {
            next();
        } else if (await userService.isAdministrator()) {
            return next({ path: '/portfolio' })
        }
        else if (await userService.isAccountManager()) {
            return next({ path: '/result' })
        }
        else if (await userService.isBackOfficeEmployee()) {
            return next({ path: '/order' })
        }
        else if (await userService.isPortfolioAdministrator()) {
            return next({ path: '/portfolio' })
        }
    } else {
        next();
    }

    if (from.name && from.name !== to.name) {
        localStorage.setItem('provider_previous_page', from.fullPath)
    }

    const deepLink = getDeepLink()

    if (deepLink) {
        router.push(deepLink)
    } else {
        next()
    }
})

export default router